var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "error-container",
    { attrs: { error: _vm.error } },
    [
      _c("transition", { attrs: { name: "slide" } }, [
        _c(
          "div",
          {
            class: {
              "container-layout": _vm.$screen.width > 576,
              "container-mobile": _vm.$screen.width <= 576,
            },
          },
          [
            _c(
              "div",
              { staticClass: "form" },
              [
                _c(
                  "b-row",
                  [
                    _c("b-col", [
                      _c(
                        "h1",
                        {
                          class: [
                            `${!_vm.$screen.sm ? "main-page-title" : ""}`,
                            { "is-pwa": _vm.$isPwa() },
                          ],
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t" +
                              _vm._s(_vm.FormMSG(18, "Carbon automation")) +
                              "\n\t\t\t\t\t\t"
                          ),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  { staticClass: "mt-3" },
                  [
                    _c(
                      "b-col",
                      { staticClass: "p-0" },
                      [
                        _c(
                          "b-card",
                          {
                            ref: "containerCarbonAutomation",
                            attrs: { "no-body": "" },
                          },
                          [
                            _c(
                              "b-card-text",
                              [
                                _c(
                                  "b-alert",
                                  { attrs: { show: "", variant: "info" } },
                                  [
                                    _c("div", { staticClass: "fs-16 fw-700" }, [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(_vm.FormMSG(125, "Notes")) +
                                          "\n\t\t\t\t\t\t\t\t\t"
                                      ),
                                    ]),
                                    _c("div", { staticClass: "pl-5" }, [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t\t1 - " +
                                          _vm._s(
                                            _vm.FormMSG(
                                              126,
                                              "If you run all automations, all automations will be launched following the list order."
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t\t"
                                      ),
                                    ]),
                                    _c("div", { staticClass: "pl-5" }, [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t\t2 -\n\t\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(
                                              127,
                                              "When you run an automation (or all automations), only the non reviewed green entries will be checked, and updated if the details/receipt match the automation criteria."
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t\t"
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c("b-col", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex row align-items-center mt-3 hide-on-tablet",
                                        },
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "5" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                { staticClass: "mb-0" },
                                                [
                                                  _vm.$screen.width >= 992
                                                    ? _c(
                                                        "b-input-group",
                                                        [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              type: "text",
                                                              id: "filterInput",
                                                              placeholder:
                                                                this.FormMSG(
                                                                  37,
                                                                  "Type to Search"
                                                                ),
                                                            },
                                                            model: {
                                                              value: _vm.filter,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.filter =
                                                                    $$v
                                                                },
                                                              expression:
                                                                "filter",
                                                            },
                                                          }),
                                                          _c(
                                                            "b-input-group-append",
                                                            {
                                                              staticClass:
                                                                "cursor-pointer",
                                                            },
                                                            [
                                                              _c(
                                                                "b-input-group-text",
                                                                {
                                                                  staticClass:
                                                                    "btn-search",
                                                                },
                                                                [
                                                                  _vm.filter
                                                                    .length ===
                                                                  0
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          "Search"
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          staticClass:
                                                                            "icon",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                "#FFFFFF",
                                                                              size: 16,
                                                                              "stroke-width": 2.5,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          "X"
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          staticClass:
                                                                            "icon",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                "#FFFFFF",
                                                                              size: 16,
                                                                              "stroke-width": 2.5,
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                _vm.filter =
                                                                                  ""
                                                                              },
                                                                          },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("b-col", { attrs: { md: "7" } }, [
                                            _c(
                                              "div",
                                              { staticClass: "float-right" },
                                              [
                                                _c(
                                                  "b-button",
                                                  {
                                                    staticClass: "mr-2",
                                                    attrs: {
                                                      variant: "primary",
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.handleClickNewAutomation,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon("Plus"),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            547,
                                                            "New automation"
                                                          )
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-button",
                                                  {
                                                    attrs: {
                                                      variant:
                                                        "custom-primary-blue",
                                                      disabled:
                                                        _vm.loadingRunAutomation,
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.handleClickRunAutomation,
                                                    },
                                                  },
                                                  [
                                                    _vm.loadingRunAutomation
                                                      ? _c("b-spinner", {
                                                          staticClass: "mr-3",
                                                          attrs: { small: "" },
                                                        })
                                                      : _vm._e(),
                                                    _c(
                                                      _vm.getLucideIcon("Play"),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            548,
                                                            "Run all automations"
                                                          )
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  { staticClass: "mt-3" },
                                  [
                                    _c(
                                      "b-col",
                                      [
                                        _vm.$screen.width >= 992
                                          ? _c("b-table", {
                                              ref: "documentPackageTable",
                                              staticStyle: {
                                                "text-align": "left",
                                              },
                                              attrs: {
                                                "selected-variant": "primary",
                                                hover: "",
                                                selectable: "",
                                                "select-mode": "single",
                                                responsive: "sm",
                                                "sticky-header": "500px",
                                                items: _vm.dataList,
                                                fields: _vm.dataFields,
                                                filter: _vm.filter,
                                                bordered: "",
                                                striped: "",
                                                small: "",
                                                "head-variant": "dark",
                                                "empty-text": _vm.FormMSG(
                                                  250,
                                                  "No data found"
                                                ),
                                                "show-empty": "",
                                                "tbody-tr-class":
                                                  _vm.setGenerateId,
                                                "tbody-tr-attr": _vm.activeDrag,
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "cell(disable)",
                                                    fn: function ({ item }) {
                                                      return [
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                attrs: {
                                                                  switch: "",
                                                                  size: "lg",
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleChangeDisable(
                                                                        $event,
                                                                        item
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    item.disable,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        item,
                                                                        "disable",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "item.disable",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                  {
                                                    key: "cell(actionDrag)",
                                                    fn: function ({ item }) {
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "text-color-rhapsody-in-blue",
                                                            attrs: {
                                                              id: `carbon-crip-vertical_${item.id}`,
                                                            },
                                                            on: {
                                                              mousedown:
                                                                _vm.startDrag,
                                                              mouseup:
                                                                _vm.endDrag,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-button",
                                                              {
                                                                attrs: {
                                                                  variant:
                                                                    "custom-transparent",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  _vm.getLucideIcon(
                                                                    "GripVertical"
                                                                  ),
                                                                  {
                                                                    tag: "component",
                                                                    attrs: {
                                                                      size: 16,
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                  {
                                                    key: "cell(option)",
                                                    fn: function ({
                                                      item,
                                                      index,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex justify-content-center",
                                                            staticStyle: {
                                                              "min-width":
                                                                "60px",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-dropdown",
                                                              {
                                                                staticClass:
                                                                  "btn-transparent",
                                                                attrs: {
                                                                  id: "dropdown-green-action",
                                                                  "no-caret":
                                                                    "",
                                                                  dropleft: "",
                                                                  boundary:
                                                                    "window",
                                                                  variant:
                                                                    "none",
                                                                  size: "sm",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "button-content",
                                                                        fn: function () {
                                                                          return [
                                                                            _c(
                                                                              _vm.getLucideIcon(
                                                                                "MoreVertical"
                                                                              ),
                                                                              {
                                                                                tag: "component",
                                                                                attrs:
                                                                                  {
                                                                                    size: 16,
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        },
                                                                        proxy: true,
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              },
                                                              [
                                                                _c(
                                                                  "b-dropdown-item",
                                                                  {
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handleClickEdit(
                                                                            item,
                                                                            index
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "text-color-rhapsody-in-blue",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          _vm.getLucideIcon(
                                                                            "Edit"
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                size: 16,
                                                                              },
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                298,
                                                                                "Edit"
                                                                              )
                                                                            ) +
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-dropdown-item",
                                                                  {
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handleClickRunAutomation(
                                                                            item.id
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "text-color-rhapsody-in-blue",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          _vm.getLucideIcon(
                                                                            "PlayCircle"
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                size: 16,
                                                                              },
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                299,
                                                                                "Run"
                                                                              )
                                                                            ) +
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-dropdown-item",
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "text-color-rhapsody-in-blue",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.handleClickCopy(
                                                                                item,
                                                                                index
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          _vm.getLucideIcon(
                                                                            "Copy"
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                size: 16,
                                                                              },
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                300,
                                                                                "Copy"
                                                                              )
                                                                            ) +
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-dropdown-item",
                                                                  {
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handleClicRemove(
                                                                            item,
                                                                            index
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "text-color-burning-tomato",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          _vm.getLucideIcon(
                                                                            "Trash2"
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                size: 16,
                                                                              },
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                301,
                                                                                "Remove"
                                                                              )
                                                                            ) +
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                1479859119
                                              ),
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("carbon-template-dialog", {
              attrs: {
                open: _vm.showCarbonTemplateDialog,
                "data-to-edit": _vm.dataToEdit,
                "is-copy-action": _vm.isCopyAction,
              },
              on: {
                "carbon-template-dialog:close":
                  _vm.handleCarbonTemplateDialogClose,
                "carbon-template:submit-success": _vm.getCarbonTemplates,
              },
            }),
            _c("view-log-automation-dialog", {
              attrs: {
                open: _vm.showViewLogAutomation,
                "log-data": _vm.logAutomation,
              },
              on: {
                "view-log-automation:close": _vm.handleViewLogAutomationClose,
              },
            }),
            _c("run-automation-option-dialog", {
              attrs: {
                open: _vm.showRunAutomationOption,
                "carbon-automation-id":
                  _vm.carbonAutomationIdSelected === null
                    ? null
                    : +_vm.carbonAutomationIdSelected,
              },
              on: {
                "run-automation-option-dialog:close": function ($event) {
                  _vm.showRunAutomationOption = false
                },
                "run-automation-option-dialog:choice":
                  _vm.handleRunAutomationOptionDialogChoice,
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }