<template>
	<error-container :error="error">
		<transition name="slide">
			<div :class="{ 'container-layout': $screen.width > 576, 'container-mobile': $screen.width <= 576 }">
				<div class="form">
					<b-row>
						<b-col>
							<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">
								{{ FormMSG(18, 'Carbon automation') }}
							</h1>
						</b-col>
					</b-row>

					<b-row class="mt-3">
						<b-col class="p-0">
							<b-card no-body ref="containerCarbonAutomation">
								<b-card-text>
									<b-alert show variant="info">
										<div class="fs-16 fw-700">
											{{ FormMSG(125, 'Notes') }}
										</div>
										<div class="pl-5">
											1 - {{ FormMSG(126, 'If you run all automations, all automations will be launched following the list order.') }}
										</div>
										<div class="pl-5">
											2 -
											{{
												FormMSG(
													127,
													'When you run an automation (or all automations), only the non reviewed green entries will be checked, and updated if the details/receipt match the automation criteria.'
												)
											}}
										</div>
									</b-alert>
									<!-- TODO: ACTIVATE THIS FILTER (DON'T REMOVE THE CODE COMMENTED) -->
									<!-- <b-row>
										<b-col>
											<filter-budget-advanced
												hide-expense-details-section
												hide-supplier-section
												:hide-entry-detail-section="false"
												hide-button-export
												hide-button-refresh-data
												:label-expense-details="FormMSG(148, 'Expense details')"
												:filter-type="FILTER_TYPE.GREEN_TABLE"
											/>
										</b-col>
									</b-row> -->
									<b-row>
										<b-col>
											<div class="d-flex row align-items-center mt-3 hide-on-tablet">
												<b-col md="5">
													<b-form-group class="mb-0">
														<b-input-group v-if="$screen.width >= 992">
															<b-form-input
																v-model="filter"
																type="text"
																id="filterInput"
																:placeholder="this.FormMSG(37, 'Type to Search')"
															/>
															<b-input-group-append class="cursor-pointer">
																<b-input-group-text class="btn-search">
																	<component
																		:is="getLucideIcon('Search')"
																		color="#FFFFFF"
																		:size="16"
																		class="icon"
																		:stroke-width="2.5"
																		v-if="filter.length === 0"
																	/>
																	<component
																		:is="getLucideIcon('X')"
																		color="#FFFFFF"
																		:size="16"
																		class="icon"
																		:stroke-width="2.5"
																		@click="filter = ''"
																		v-else
																	/>
																</b-input-group-text>
															</b-input-group-append>
														</b-input-group>
													</b-form-group>
												</b-col>
												<b-col md="7">
													<div class="float-right">
														<b-button variant="primary" class="mr-2" @click="handleClickNewAutomation">
															<component :is="getLucideIcon('Plus')" :size="16" />
															{{ FormMSG(547, 'New automation') }}
														</b-button>
														<b-button
															variant="custom-primary-blue"
															:disabled="loadingRunAutomation"
															@click="handleClickRunAutomation"
														>
															<b-spinner v-if="loadingRunAutomation" small class="mr-3" />
															<component :is="getLucideIcon('Play')" :size="16" />
															{{ FormMSG(548, 'Run all automations') }}
														</b-button>
													</div>
												</b-col>
											</div>
										</b-col>
									</b-row>
									<b-row class="mt-3">
										<b-col>
											<b-table
												v-if="$screen.width >= 992"
												selected-variant="primary"
												hover
												selectable
												select-mode="single"
												responsive="sm"
												ref="documentPackageTable"
												sticky-header="500px"
												:items="dataList"
												style="text-align: left"
												:fields="dataFields"
												:filter="filter"
												bordered
												striped
												small
												head-variant="dark"
												:empty-text="FormMSG(250, 'No data found')"
												show-empty
												:tbody-tr-class="setGenerateId"
												:tbody-tr-attr="activeDrag"
											>
												<template #cell(disable)="{ item }">
													<div>
														<b-form-checkbox v-model="item.disable" switch size="lg" @change="handleChangeDisable($event, item)" />
													</div>
												</template>
												<template #cell(actionDrag)="{ item }">
													<div
														class="text-color-rhapsody-in-blue"
														:id="`carbon-crip-vertical_${item.id}`"
														@mousedown="startDrag"
														@mouseup="endDrag"
													>
														<b-button variant="custom-transparent">
															<component :is="getLucideIcon('GripVertical')" :size="16" />
														</b-button>
													</div>
												</template>
												<template #cell(option)="{ item, index }">
													<div class="d-flex justify-content-center" style="min-width: 60px">
														<b-dropdown
															id="dropdown-green-action"
															no-caret
															dropleft
															boundary="window"
															class="btn-transparent"
															variant="none"
															size="sm"
														>
															<template #button-content>
																<component :is="getLucideIcon('MoreVertical')" :size="16" />
															</template>
															<b-dropdown-item @click="handleClickEdit(item, index)">
																<span class="text-color-rhapsody-in-blue">
																	<component :is="getLucideIcon('Edit')" :size="16" />&nbsp;{{ FormMSG(298, 'Edit') }}
																</span>
															</b-dropdown-item>
															<b-dropdown-item @click="handleClickRunAutomation(item.id)">
																<span class="text-color-rhapsody-in-blue">
																	<component :is="getLucideIcon('PlayCircle')" :size="16" />&nbsp;{{ FormMSG(299, 'Run') }}
																</span>
															</b-dropdown-item>
															<b-dropdown-item>
																<span class="text-color-rhapsody-in-blue" @click="handleClickCopy(item, index)">
																	<component :is="getLucideIcon('Copy')" :size="16" />&nbsp;{{ FormMSG(300, 'Copy') }}
																</span>
															</b-dropdown-item>
															<b-dropdown-item @click="handleClicRemove(item, index)">
																<span class="text-color-burning-tomato">
																	<component :is="getLucideIcon('Trash2')" :size="16" />&nbsp;{{ FormMSG(301, 'Remove') }}
																</span>
															</b-dropdown-item>
														</b-dropdown>
													</div>
												</template>
											</b-table>
										</b-col>
									</b-row>
								</b-card-text>
							</b-card>
						</b-col>
					</b-row>
				</div>

				<carbon-template-dialog
					:open="showCarbonTemplateDialog"
					:data-to-edit="dataToEdit"
					:is-copy-action="isCopyAction"
					@carbon-template-dialog:close="handleCarbonTemplateDialogClose"
					@carbon-template:submit-success="getCarbonTemplates"
				/>

				<view-log-automation-dialog :open="showViewLogAutomation" :log-data="logAutomation" @view-log-automation:close="handleViewLogAutomationClose" />

				<run-automation-option-dialog
					:open="showRunAutomationOption"
					:carbon-automation-id="carbonAutomationIdSelected === null ? null : +carbonAutomationIdSelected"
					@run-automation-option-dialog:close="showRunAutomationOption = false"
					@run-automation-option-dialog:choice="handleRunAutomationOptionDialogChoice"
				/>
			</div>
		</transition>
	</error-container>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import FilterBudgetAdvanced from '@/components/Budget/FilterBudgetAdvanced.vue';
import CarbonTemplateDialog from '@/modules/carbonclap/components/carbon-template/CarbonTemplateDialog.vue';
import { getCarbonTemplates, deleteCarbonTemplate, addUpdCarbonTemplate } from '@/cruds/carbon.crud';
import _ from 'lodash';
import { runAutomation, moveAndReorganizeCarbonTemplatePosition } from '@/cruds/carbon.crud';
import ViewLogAutomationDialog from '@/modules/carbonclap/components/carbon-template/ViewLogAutomationDialog.vue';
import RunAutomationOptionDialog from '@/modules/carbonclap/components/carbon-template/RunAutomationOptionDialog.vue';
import { FILTER_TYPE } from '@/shared/constants';

export default {
	name: 'CarbonTemplateList',

	mixins: [languageMessages, globalMixin],

	components: {
		FilterBudgetAdvanced,
		CarbonTemplateDialog,
		ViewLogAutomationDialog,
		RunAutomationOptionDialog
	},

	data() {
		return {
			error: {},
			filter: '',
			dataList: [],
			dataToEdit: {},
			showCarbonTemplateDialog: false,
			loadingRunAutomation: false,
			showViewLogAutomation: false,
			logAutomation: {},
			isCopyAction: false,
			draggingIndex: -1,
			draggedOverIndex: -1,
			positionIndex: null,
			positionOverIndex: null,
			dragging: false,
			showRunAutomationOption: false,
			carbonAutomationIdSelected: 0,
			FILTER_TYPE
		};
	},

	computed: {
		dataFields() {
			return [
				{
					key: 'actionDrag',
					label: '',
					class: 'text-center',
					sortable: false
				},
				{
					key: 'position',
					label: this.FormMSG(289, 'Position'),
					class: 'text-left',
					sortable: true
				},
				{
					key: 'name',
					label: this.FormMSG(185, 'Name/Description'),
					class: 'text-left',
					sortable: true
				},
				{
					key: 'disable',
					label: this.FormMSG(598, 'Disable'),
					class: 'text-center',
					sortable: false
				},
				{
					key: 'costCenters',
					label: this.FormMSG(186, 'Cost codes'),
					class: 'text-left',
					formatter: (value, key, item) => {
						return this.checkLengthLabelForSelect(value);
					},
					sortable: true
				},
				{
					key: 'supplier.name',
					label: this.FormMSG(86, 'Supplier'),
					class: 'text-left',
					sortable: true
				},
				{
					key: 'keyWordOne',
					label: this.FormMSG(87, 'Keyword 1'),
					class: 'text-left',
					sortable: true
				},
				{
					key: 'keyWordTwo',
					label: this.FormMSG(88, 'Keyword 2'),
					class: 'text-left',
					sortable: true
				},
				{
					key: 'keyWordThree',
					label: this.FormMSG(89, 'Keyword 3'),
					class: 'text-left',
					sortable: true
				},
				{
					key: 'coTwoCategory',
					label: this.FormMSG(90, 'Green detail type'),
					class: 'text-left',
					formatter: (value) => {
						return this.GetTextFromMenuNumberAndMenuValue(1334, value);
					},
					sortable: true
				},
				{
					key: 'userId',
					label: this.FormMSG(91, 'Added by'),
					class: 'text-left',
					formatter: (value, key, item) => {
						return `${item.user.name} ${item.user.firstName}`;
					},
					sortable: true
				},
				{
					key: 'numberOfUse',
					label: this.FormMSG(92, 'Number of use'),
					class: 'text-right',
					sortable: true
				},
				// {
				// 	key: 'efficiency',
				// 	label: this.FormMSG(93, 'Efficiency'),
				// 	class: 'text-right',
				// 	sortable: true
				// },
				{
					key: 'option',
					label: this.FormMSG(94, 'Option'),
					class: 'text-center',
					sortable: true
				}
			];
		}
	},
	async created() {
		await this.getCarbonTemplates();
	},

	methods: {
		async handleChangeDisable(payload, item) {
			await addUpdCarbonTemplate(item.id, {
				..._.omit(item, ['supplier', 'carbonValues', 'user']),
				disable: payload
			});

			this.createToastForMobile(this.FormMSG(814, 'Success'), this.FormMSG(825, 'Data updated successfully!'));
		},
		async handleRunAutomationOptionDialogChoice({ choice, idSelected }) {
			await this.runAutomationAction(idSelected, choice);
		},
		startDrag() {
			this.dragging = true;
		},
		endDrag() {
			this.dragging = false;
		},
		async handleViewLogAutomationClose() {
			this.showViewLogAutomation = false;

			await this.getCarbonTemplates();
		},
		handleDragStart(item) {
			if (this.dragging) {
				let index = +item.index;
				this.draggingIndex = index;
				this.positionIndex = +item.id;
			}
		},
		handleDragEnd() {
			this.draggingIndex = -1;
			this.draggedOverIndex = -1;
			this.positionIndex = null;
			this.positionOverIndex = null;
		},
		handleDragEnter(item) {
			if (this.dragging) {
				let index = +item.index;
				this.draggedOverIndex = index;
				this.positionOverIndex = +item.position;
			}
		},
		async handleDrop() {
			if (this.dragging && this.draggingIndex > -1 && this.draggedOverIndex > -1) {
				let globalData = this.dataList;
				let movedEnd = null;
				const movedItem = this.dataList.splice(this.draggingIndex, 1)[0];
				if (this.draggingIndex < this.draggedOverIndex) {
					movedEnd = globalData.slice(this.draggedOverIndex - 1)[0];
				} else {
					movedEnd = globalData.slice(this.draggedOverIndex)[0];
				}
				let _movedItem = { ...movedItem, position: movedEnd.position };
				this.dataList.splice(this.draggedOverIndex, 0, _movedItem);
				this.dataList = this.dataList.map((item, index) => ({ ...item, index: index }));
				this.draggingIndex = -1;
				this.draggedOverIndex = -1;
				await moveAndReorganizeCarbonTemplatePosition(this.positionIndex, this.positionOverIndex);
				this.positionIndex = null;
				this.positionOverIndex = null;
				this.dragging = false;
			}
		},
		activeDrag() {
			return { draggable: true };
		},
		// drag and drop initial
		setGenerateId(item) {
			let initClass = () => {
				if (item) {
					return `carbon-${item.index} ${+this.draggedOverIndex === +item.index ? 'is-active-generate-id' : ''}`;
				}
				return '';
			};
			let result = initClass();
			if (result.length !== 0) {
				setTimeout(() => {
					let element = document.querySelector(`.${result}`);
					if (element) {
						element.addEventListener('dragstart', () => this.handleDragStart(item));
						element.addEventListener('dragend', () => this.handleDragEnd());
						element.addEventListener('dragover', (e) => {
							e.preventDefault();
						});
						element.addEventListener('dragenter', () => _.debounce(this.handleDragEnter(item), 200));
						element.addEventListener('drop', () => this.handleDrop());
					}
				}, 100);
				return result + ' p-2 carbon-item';
			}
		},
		async handleClickRunAutomation(carbonTemplateId = null) {
			this.carbonAutomationIdSelected = carbonTemplateId;
			this.showRunAutomationOption = true;
		},
		async runAutomationAction(carbonTemplateId = null, choice = 0) {
			const actionForLoader = async () => {
				this.loadingRunAutomation = true;

				this.logAutomation = await runAutomation(carbonTemplateId, choice === 1);
				this.showViewLogAutomation = true;

				this.loadingRunAutomation = false;
			};

			await this.showLoader(actionForLoader, 'containerCarbonAutomation');
		},
		handleClickCopy(item, index) {
			this.isCopyAction = true;

			this.handleClickEdit(item);
		},
		async handleClicRemove(item, index) {
			const action = async () => {
				await deleteCarbonTemplate(item.id);

				await this.getCarbonTemplates();

				this.createToastForMobile(this.FormMSG(814, 'Success'), this.FormMSG(815, 'Data removed successfully!'));
			};

			this.confirmModal(action, this.FormMSG(816, 'Are you sure ?'));
		},
		handleClickEdit(item, index) {
			this.dataToEdit = item;
			this.showCarbonTemplateDialog = true;
		},
		async getCarbonTemplates() {
			const actionForLoader = async () => {
				this.dataList = [...(await getCarbonTemplates())].map((item, index) => ({ ...item, index: index }));
			};

			await this.$nextTick(async () => {
				await this.showLoader(actionForLoader, 'containerCarbonAutomation');
			});
		},
		handleCarbonTemplateDialogClose() {
			this.dataToEdit = {};
			this.isCopyAction = false;
			this.showCarbonTemplateDialog = false;
		},
		handleClickNewAutomation() {
			this.showCarbonTemplateDialog = true;
		}
	}
};
</script>
<style>
.is-active-generate-id {
	background-color: #00a09c !important;
}
.carbon-item {
	transition: all 200ms ease-in-out;
}
</style>
